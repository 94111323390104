<template>
  <va-inner-loading :loading="loading" icon="restart_alt">
    <va-card v-if="requests.length > 0" class="va-table-responsive p-3 mt-4">
      <va-card-content>
        <table class="va-table va-table--hoverable w-100">
          <thead>
            <tr>
              <th>{{ $t("id") }}</th>
              <th>{{ $t("championship_name") }}</th>
              <th>{{ $t("view_more") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              style="line-height: 1.5"
              v-for="(request, index) in requests"
              :key="request.id"
            >
              <td>{{ index + offset + 1 }}</td>
              <td>{{ request.name }}</td>
              <td>
                <va-button
                  color="info"
                  flat
                  icon="eye"
                  @click="ViewRequestDetails(request)"
                ></va-button>
              </td>
            </tr>
          </tbody>
        </table>
        <va-pagination
          v-if="pages > 1"
          color="#0a4695"
          style="direction: ltr"
          class="mt-5"
          v-model="currentPage"
          :visible-pages="3"
          :pages="pages"
        />
      </va-card-content>
    </va-card>
    <div v-else>
      <va-card
        class="row align--center mt-4"
        style="height: 35vh"
        stripe
        stripe-color="#1f1f62"
      >
        <va-card-content>
          <h1 class="text-center" style="font-size: 32px">
            {{ $t("no_requests") }}
            <va-icon class="material-icons" size="32px">
              comments_disabled
            </va-icon>
          </h1>
        </va-card-content>
      </va-card>
    </div>
  </va-inner-loading>
</template>

<script>
export default {
    name:'champs-logo',
    data() {
      return {
        requests:[],
      }
    },
}
</script>

<style>

</style>