<template>
  <!-- Breadcrumb -->
  <va-card class="row align--center">
    <va-card-content>
      <va-breadcrumbs color="primary">
        <va-breadcrumbs-item :label="$t('menu.logos')" />
      </va-breadcrumbs>
    </va-card-content>
  </va-card>
  <!-- Tabs -->
  <va-tabs class="mt-4" center hide-slider grow v-model="selectedTab">
    <template #tabs>
      <va-tab name="1" color="primary">
        <va-badge right :text="newRequestsCount" class="mr-4">
          <span class="material-icons">emoji_events</span>
          <h4 style="padding: 0 10px">{{ $t("championships") }}</h4>
        </va-badge>
      </va-tab>
      <va-tab name="2" color="success">
        <span class="material-icons">flag</span>
        <h4 style="padding: 0 10px">{{ $t("menu.teams") }}</h4>
      </va-tab>
    </template>
    <div v-if="selectedTab == '1'">
        <champs-logos />
    </div>
    <div v-if="selectedTab == '2'">
        <teams-logos />
    </div>
  </va-tabs>
</template>

<script>
import ChampsLogos from "./components/ChampsLogos.vue";
import TeamsLogos from "./components/TeamsLogos.vue";
export default {
  components: { ChampsLogos, TeamsLogos },
  data(){
      return{
          selectedTab : '1'
      }
  }
};
</script>

<style></style>
